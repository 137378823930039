<template>
    <div>
        <x-header>
            <img slot="headerImage" src="../../../assets/guojihaiyun/banner.jpg" alt="">
            <h1 slot="headerTitle">{{$store.state.navShow == true ? '国际海运—海运＋多联运输派送模式' : 'Shipping - Shipping + Multimodal Transportation Delivery Mode'}}</h1>
        </x-header>
        <div class="inter_main_content">
            <div class="inter_main">
                <p>{{$store.state.navShow == true ?'MG集团拥有十余年的海运服务经验，专业成熟的操作团队可提供安全、高效的定制化海案。路线：由中国各大港口通过海运到达汉堡/阿姆斯特丹/ 鹿特丹/ 圣彼得堡等城市，并提供专业的清关服务。' : 'MG group has more than ten years of experience in marine service, and its professional and mature operation team can provide safe and efficient customized sea cases. Route: arrive at Hamburg / AMSTERDAM / Rotterdam / St. Petersburg and other cities by sea from major ports in China, and provide professional customs clearance services.'}}</p>
                <div class="guoji_Items" v-for="(item,index) in $store.state.navShow == true ? guojiItemsArr : guojiItemsArrE" :key="index" style="overflow: hidden;">
                    <div class="guoji_Items_l" :data-aos="index % 2 == 0 ? 'fade-right' : 'fade-left'" data-aos-offset="300" data-aos-easing="ease-in-sine" >
                        <img class="guoji_Items_l_img" style="height:100%;" :src="item.itemsLeftImg" alt="">
                    </div>
                    <div class="guoji_Items_r">

                         <div :class="$store.state.navShow == true ? 'content_kuang' : 'content_kuangE'">
                            <img :src="item.itemsRightIcon" alt="" >
                            <p class="items_title"  >{{item.itemsRightTitle}}</p>
                            <p class="items_content" >{{item.items_content1}}</p>
                            <p class="items_content" >{{item.items_content2}}</p>
                            <p class="items_content" >{{item.items_content3}}</p>
                        </div>

                    </div>
                </div>

            </div>
            <div class="footerImg">
                <img src="../../../assets/guojihaiyun/footer_img.jpg" alt="">
            </div>
        </div>

    </div>
</template>
<script>
// 头尾导入
import xHeader from '../../../components/header.vue'
export default {
  components: {
    // 使用/注册引入的组件 components
    xHeader
  },
  data () {
    return {
      guojiItemsArr: [
        {
          itemsLeftImg: require('../../../assets/guojihaiyun/main1.jpg'),
          itemsRightIcon: require('../../../assets/guojihaiyun/icon.png'),
          itemsRightTitle: '整柜海运服务',
          items_content1: '船舶可用空间管理',
          items_content2: '经济效益最大化运转方案',
          items_content3: '灵活的弹性行程安排'
        },
        {
          itemsLeftImg: require('../../../assets/guojihaiyun/main2.jpg'),
          itemsRightIcon: require('../../../assets/guojihaiyun/duolanpaisongicon.png'),
          itemsRightTitle: '多联派送服务',
          items_content1: '货物送达港口后的专属清关团队',
          items_content2: '多式联运解决方案制定',
          items_content3: '限时到达及门到门运输服务'
        },
        {
          itemsLeftImg: require('../../../assets/guojihaiyun/main3.jpg'),
          itemsRightIcon: require('../../../assets/guojihaiyun/yunshufanganIcon.png'),
          itemsRightTitle: '项目运输方案定制服务',
          items_content1: '海陆空铁高效配合完美运输方案',
          items_content2: '整合全球所有关键线路及港口情况信息',
          items_content3: '成本与时效相结合的定制服务'
        },
        {
          itemsLeftImg: require('../../../assets/guojihaiyun/main4.jpg'),
          itemsRightIcon: require('../../../assets/guojihaiyun/jingwaishangwuduijie.png'),
          itemsRightTitle: '境外商务对接咨询服务',
          items_content1: '境外法务咨询',
          items_content2: '境外财务咨询',
          items_content3: '境外仓储服务'
        }
      ],
      guojiItemsArrE: [
        {
          itemsLeftImg: require('../../../assets/guojihaiyun/main1.jpg'),
          itemsRightIcon: require('../../../assets/guojihaiyun/icon.png'),
          itemsRightTitle: 'FCL shipping service',
          items_content1: 'Ship space management',
          items_content2: 'Operation plan for maximizing economic benefits',
          items_content3: 'Flexible and flexible itinerary'
        },
        {
          itemsLeftImg: require('../../../assets/guojihaiyun/main2.jpg'),
          itemsRightIcon: require('../../../assets/guojihaiyun/duolanpaisongicon.png'),
          itemsRightTitle: 'Multiple delivery service',
          items_content1: 'Exclusive customs clearance team after the goods arrive at the port',
          items_content2: 'Multimodal transport solution development',
          items_content3: 'Time-limited arrival and door-to-door shipping services'
        },
        {
          itemsLeftImg: require('../../../assets/guojihaiyun/main3.jpg'),
          itemsRightIcon: require('../../../assets/guojihaiyun/yunshufanganIcon.png'),
          itemsRightTitle: 'Customized service of project transportation plan',
          items_content1: 'Efficient coordination of sea, land, air and rail with a perfect transportation solution',
          items_content2: 'Integrate information on all key routes and port conditions around the world',
          items_content3: 'Customized service combining cost and timeliness'
        },
        {
          itemsLeftImg: require('../../../assets/guojihaiyun/main4.jpg'),
          itemsRightIcon: require('../../../assets/guojihaiyun/jingwaishangwuduijie.png'),
          itemsRightTitle: 'Overseas business docking consulting services',
          items_content1: 'Overseas legal consultation',
          items_content2: 'Overseas Financial Consulting',
          items_content3: 'Overseas warehousing service'
        }
      ]
    }
  }
}
</script>

<style lang="less" scoped>
    .inter_main_content{
        position: relative;
        z-index: -1;
        padding-bottom: 201px;
        background: #fafafa;
    }
    .inter_main{
        width:var(--width1200px);
        margin: 115px auto 0;
        & > p{
            font-family: Microsoft YaHei;
            color: #666666;
            text-align: left;
            text-indent: 32px;
            margin-bottom:75px;
        }
        .guoji_Items{
            width: 100%;
            height: 425px;
            background: #2e67b1;
            margin-bottom: 38px;
            display: flex;
            flex-direction: row;
            overflow:hidden
            .guoji_Items_l{
                // width: 757px;
                height: 100%;
                // background: orange;

            }
            .guoji_Items_r{
                .content_kuang{
                    // width: 176px;
                    // background: orange;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    // justify-content: center;
                    color: #FFFFFF;
                    font-family: Microsoft YaHei;
                    margin: 70px 0  0 80px;
                    &>img{
                        width: 46px;
                        height: 45px;
                    }
                    & > .items_title{
                        font-size: 24px;
                        font-weight: bold;
                        margin: 30px 0;
                        margin: 30px 0 25px 0;
                    }
                    & > .items_content{
                        font-size: 16px;
                        line-height: 37px;
                        // text-align: left;
                    }

                }

                 .content_kuangE{
                    // width: 176px;
                    // background: orange;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    // justify-content: center;
                    color: #FFFFFF;
                    font-family: Microsoft YaHei;
                    margin: 65px 0  0 65px;
                    &>img{
                        width: 46px;
                        height: 45px;
                    }
                    & > .items_title{
                        font-size: 22px;
                        font-weight: bold;
                        margin: 25px 0;
                        margin: 25px 0 20px 0;
                    }
                    & > .items_content{
                        font-size: 16px;
                        line-height: 35px;
                        // text-align: left;
                    }

                }

            }

            &:nth-child(even){
                // background: green;
                //  flex-direction: row-reverse;
                .guoji_Items_r{
                    .content_kuang{
                          & > .items_content{
                              font-size: 16px;
                              line-height: 37px;
                              text-align: left;
                          }
                      }
                       .content_kuangE{
                          & > .items_content{
                              font-size: 16px;
                              line-height: 30px;
                              text-align: left;
                          }
                      }
                }
            }
              &:nth-child(odd){
                // background: green;
                 flex-direction: row-reverse;
                .guoji_Items_r{
                    .content_kuang{
                        text-align: right;
                        align-items: flex-end;
                        margin: 70px 80px 0  0 ;
                        &>img{
                            float: right;
                        }
                    }
                    .content_kuangE{
                        text-align: right;
                        align-items: flex-end;
                        margin: 60px 65px 0  0 ;
                        &>img{
                            float: right;
                        }
                    }
                }
            }
            &:last-child{
                margin-bottom: 0;
            }
        }
    }
    .footerImg{
        // height: 1010px;
        // background: orange;
        position: absolute;
        font-size: 0;
        z-index: -1;
        bottom: 0;
    }
</style>
